<template>
  <section
    class="configure-document entity-edit-page"
    id="drag-template-background"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div class="flow-top-bar">
      <div class="left-side">
        <div class="close-icon">
          <button
            type="button"
            class="btn btn-dark mr-1"
            @click="goBack"
            style="display: flex"
          >
            <i class="fa fa-arrow-left" style="margin-top: 3px"></i>
            {{ getIsMobile ? "" : "Back" }}
          </button>
        </div>
        <el-form :class="!this.isEdit ? 'form-title hide-title' : 'form-title'">
          <el-input
            v-model="entityName"
            placeholder="Enter Entity Title"
            class="input-type-one"
          ></el-input>
          <p class="error" v-if="!entityName"></p>
        </el-form>
      </div>
      <el-col
        :xl="14"
        :lg="14"
        :md="14"
        :sm="16"
        :xs="16"
        :class="
          getIsMobile ? '' : 'flow-top-bar-middle-area document-flow-wrapper'
        "
      >
        <el-steps
          finish-status="success"
          :space="300"
          align-center
          class="document-flow"
          :style="getIsMobile ? 'margin-top:-12px' : 'mb-1'"
          :active="activeStep"
        >
          <el-step title="Builder" @click.native="builderStep(0)"></el-step>
          <el-step
            title="Primary Fields"
            @click.native="builderStep(1)"
          ></el-step>

          <el-step
            title="Relationships"
            @click.native="builderStep(2)"
          ></el-step>
          <el-step
            title="Customization"
            @click.native="builderStep(3)"
          ></el-step>
          <el-step title="Actions" @click.native="builderStep(4)"></el-step>
        </el-steps>
      </el-col>
      <el-col
        :xl="4"
        :lg="4"
        :md="4"
        :sm="8"
        :xs="8"
        :class="getIsMobile ? '' : 'flow-top-bar-right-area'"
      >
        <div style="justify-content: right; display: flex; height: 50px">
          <el-button
            @click="openNewEntityModal"
            :class="getIsMobile ? '' : 'mt-1 mr-1 info-title'"
            type="primary"
          >
            <i class="el-icon-s-tools"></i>
          </el-button>
          <el-button
            :underline="false"
            @click="updateEntityData(true)"
            :class="getIsMobile ? '' : 'mt-1 mr-1'"
            type="primary"
            v-if="isEdit && this.activeStep == 1"
            size="mini"
            >{{ getIsMobile ? "Close" : "Save & close" }}</el-button
          >
          <el-button
            :underline="false"
            size="mini"
            @click="saveEntity"
            class="mt-1 mr-1"
            type="primary"
            v-if="!isEdit && this.activeStep != 2"
            >{{ getIsMobile ? "Save" : "Save &amp; Next" }}</el-button
          >

          <el-button
            :underline="false"
            @click="updateEntityData(false)"
            :class="getIsMobile ? '' : 'mt-1 mr-1'"
            type="primary"
            size="mini"
            v-else-if="isEdit && this.activeStep != 4"
            >{{ getIsMobile ? "Update" : "Update & Next" }}</el-button
          >
          <el-button
            :underline="false"
            @click="checkAutomation"
            class="mt-1 mr-1"
            type="primary"
            size="mini"
            v-else-if="this.activeStep == 4"
            >Done</el-button
          >

          <div
            class="action-wrapper-type-two float-right"
            :style="getIsMobile ? 'margin-top:-45px' : ''"
          >
            <el-button
              slot="reference"
              class="rounded-0 btn-one type-2"
              type="primary"
              size="mini"
              v-on:click="openRightSideBar()"
              :disabled="getIsRSOpen"
              v-if="!getIsRSOpen && activeStep === 0"
              >&lt;&lt;</el-button
            >
            <el-button
              slot="reference"
              size="mini"
              class="rounded-0 btn-one close type-2"
              type="primary"
              v-on:click="closeRightSideBar()"
              v-if="getIsRSOpen && activeStep === 0"
              >&gt;&gt;</el-button
            >
          </div>
        </div>
      </el-col>
    </div>

    <el-row style="margin-top: 10px; margin-left: 10px">
      <DropTemplates
        v-if="activeStep === 0"
        :allSelectedEntity="selectedEntity"
        :allTemplates="allTemplates"
        :isSubmit="isSubmit"
        :isEdit="isEdit"
        v-on:getTemplates="updateEntityTemplates"
        :entityType="entity_type"
      ></DropTemplates>

      <!-- <PrimaryField
      v-if="activeStep === 1"
      :selectedEntity="selectedEntity"
      :primaryFields="primaryFields"
      ></PrimaryField>-->
      <div class="dragAndDrop" v-if="activeStep === 1">
        <!-- <el-scrollbar
          wrap-style="max-height: calc(100vh - 80px);"
          class="outer-scroll"
        > -->
        <el-row :gutter="10">
          <el-col :span="12">
            <div style="text-align: center" class="dropBox">
              <h3 class="head pt-1 pb-1">All Fields</h3>
              <p class="text pb-1" v-if="!draggableFields.length">
                The fields list is empty
              </p>
              <!-- <el-scrollbar wrap-style="max-height: calc(100vh - 180px);"> -->
              <draggable
                class="list-group"
                tag="ul"
                :list="draggableFields"
                v-bind="dragOptions"
                :move="onMove"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  :name="'flip-list'"
                  class="contain-length"
                >
                  <li
                    class="list-group-item"
                    v-for="element in draggableFields"
                    :key="element.key"
                  >
                    {{ element.label }}
                  </li>
                </transition-group>
              </draggable>
              <!-- </el-scrollbar> -->
              <div style="margin-bottom: 1rem"></div>
            </div>
          </el-col>

          <el-col :span="12">
            <div style="text-align: center" class="dropBox">
              <div class="emp-box">
                <h3 class="head pt-1 pb-1">Primary Fields</h3>
                <p class="text pb-1" v-if="!primaryFields.length">
                  Drag the fields from the left panel and drop here to add them
                </p>
              </div>
              <!-- <el-scrollbar wrap-style="max-height: calc(100vh - 180px);"> -->
              <draggable
                element="span"
                v-model="primaryFields"
                v-bind="dragOptions"
                :move="onMove"
              >
                <transition-group name="no" class="list-group" tag="ul">
                  <li
                    class="list-group-item"
                    v-for="element in primaryFields"
                    :key="element.key"
                  >
                    <el-checkbox
                      v-if="
                        selectedEntity &&
                        selectedEntity[0] &&
                        selectedEntity[0].template_id &&
                        element.template_id == selectedEntity[0].template_id
                      "
                      v-model="element.is_primary"
                      @change="updatedPrimaryFields(element.key)"
                    >
                      {{ element.label }}
                    </el-checkbox>
                    <p v-else>
                      {{ element.label }}
                    </p>
                  </li>
                </transition-group>
              </draggable>
              <!-- </el-scrollbar> -->
              <div style="margin-bottom: 1rem"></div>
            </div>
          </el-col>
        </el-row>
        <!-- </el-scrollbar> -->
      </div>

      <div class="relationships" v-if="activeStep === 2">
        <el-row>
          <el-col :span="24">
            <el-form
              :inline="true"
              :model="newRelationship"
              class="demo-form-inline"
              label-position="left"
            >
              <el-row>
                <el-form-item>
                  <el-select
                    v-model="newRelationship.parent_entity_id"
                    disabled
                    filterable
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.parent_entity_id"
                  >
                    {{ getErrors.parent_entity_id }}
                  </p>
                </el-form-item>
                <el-form-item label="Has">
                  <el-select
                    v-model="newRelationship.child_relation_type"
                    filterable
                    size="mini"
                  >
                    <el-option value="MANY">MANY</el-option>
                    <el-option value="ONE">ONE</el-option>
                  </el-select>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.child_relation_type"
                  >
                    {{ getErrors.child_relation_type }}
                  </p>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="newRelationship.child_entity_id"
                    @change="checkEntitiesParentTitle"
                    filterable
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of otherEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>

                  <p
                    class="error"
                    v-if="getErrors && getErrors.child_entity_id"
                  >
                    {{ getErrors.child_entity_id }}
                  </p>
                </el-form-item>
                <el-form-item label="As">
                  <el-input
                    v-model="newRelationship.child_relation_title"
                    size="mini"
                    placeholder="Enter name"
                  ></el-input>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.child_relation_title"
                  >
                    {{ getErrors.child_relation_title }}
                  </p>
                </el-form-item>

                <el-form-item>
                  <el-checkbox
                    v-model="newRelationship.is_bidirectional"
                  ></el-checkbox>
                </el-form-item>
              </el-row>

              <el-row v-if="newRelationship.is_bidirectional">
                <el-form-item>
                  <el-select
                    v-model="newRelationship.child_entity_id"
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.child_entity_id"
                  >
                    {{ getErrors.child_entity_id }}
                  </p>
                </el-form-item>

                <el-form-item label="Has">
                  <el-select
                    v-model="newRelationship.parent_relation_type"
                    size="mini"
                  >
                    <el-option value="MANY">MANY</el-option>
                    <el-option value="ONE">ONE</el-option>
                  </el-select>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.parent_relation_type"
                  >
                    {{ getErrors.parent_relation_type }}
                  </p>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="newRelationship.parent_entity_id"
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.parent_entity_id"
                  >
                    {{ getErrors.parent_entity_id }}
                  </p>
                </el-form-item>
                <el-form-item label="As">
                  <el-input
                    v-model="newRelationship.parent_relation_title"
                    size="mini"
                    placeholder="Enter name"
                  ></el-input>
                  <p
                    class="error"
                    v-if="getErrors && getErrors.parent_relation_title"
                  >
                    {{ getErrors.parent_relation_title }}
                  </p>
                </el-form-item>
              </el-row>

              <p class="error" v-if="getErrors && getErrors.critical_error">
                {{ getErrors.critical_error }}
              </p>

              <el-button
                @click="addNewRelationship"
                type="primary"
                size="mini"
                :disabled="!newRelationship.child_entity_id"
                >Add Relationship</el-button
              >
            </el-form>
          </el-col>
        </el-row>

        <hr />

        <h3>Entity Relationships From Templates</h3>

        <el-button @click="fetchExistedRelationships" size="mini"
          >Sync Relationships</el-button
        >

        <hr />
        <el-row>
          <el-col :span="11">
            <el-table :data="newRelationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="140"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_entity_id
                    "
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="100"
              >
                <template slot-scope="scope">
                  {{ newRelationshipsData[scope.$index].child_relation_type }}
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="140"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="newRelationshipsData[scope.$index].child_entity_id"
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_relation_title"
                label="As"
                width="140"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="
                      newRelationshipsData[scope.$index].child_relation_title
                    "
                    size="mini"
                    placeholder="Enter name"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

          <el-col :span="13">
            <el-table :data="newRelationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="140"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="newRelationshipsData[scope.$index].child_entity_id"
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="140"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_relation_type
                    "
                    size="mini"
                  >
                    <el-option label="MANY" value="MANY"></el-option>
                    <el-option label="ONE" value="ONE"></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="140"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="
                      newRelationshipsData[scope.$index].parent_entity_id
                    "
                    disabled
                    size="mini"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="parent_relation_title"
                label="As"
                width="120"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="
                      newRelationshipsData[scope.$index].parent_relation_title
                    "
                    size="mini"
                    placeholder="Enter name"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="parent_relation_title"
                label="Actions"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-check"
                    @click="addLimit(scope.$index)"
                    type="success"
                    size="mini"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>

        <el-row>
          <h3>All Entity Relationships</h3>
          <el-col :span="11">
            <el-table :data="relationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="160"
              >
                <template slot-scope="scope">
                  <el-select
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                    v-model="relationshipsData[scope.$index].parent_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                    v-model="relationshipsData[scope.$index].child_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="80"
              >
                <template slot-scope="scope">
                  <span
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                  >
                    {{ relationshipsData[scope.$index].child_relation_type }}
                  </span>
                  <span
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                  >
                    {{ relationshipsData[scope.$index].parent_relation_type }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="160"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="relationshipsData[scope.$index].child_entity_id"
                    disabled
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>

                  <el-select
                    v-model="relationshipsData[scope.$index].parent_entity_id"
                    disabled
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_relation_title"
                label="As"
                width="140"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="el-tooltips"
                    :content="
                      relationshipsData[scope.$index].child_relation_title
                    "
                    placement="top"
                  >
                    <span
                      v-if="
                        relationshipsData[scope.$index].owner_type == 'PARENT'
                      "
                      class="truncate-text"
                    >
                      {{
                        relationshipsData[scope.$index].child_relation_title
                          | truncate(10, "...")
                      }}
                    </span>
                  </el-tooltip>
                  <span
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                    class="truncate-text"
                  >
                    {{ relationshipsData[scope.$index].parent_relation_title }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

          <el-col :span="13">
            <el-table :data="relationshipsData" style="width: 100%">
              <el-table-column
                prop="parent_entity_id"
                label="Entity"
                width="160"
              >
                <template
                  slot-scope="scope"
                  v-if="relationshipsData[scope.$index].is_bidirectional"
                >
                  <el-select
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                    v-model="relationshipsData[scope.$index].parent_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                    v-model="relationshipsData[scope.$index].child_entity_id"
                    disabled
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
                <template v-else>
                  <b>-</b>
                </template>
              </el-table-column>
              <el-table-column
                prop="child_relation_type"
                label="Has"
                width="80"
              >
                <template
                  slot-scope="scope"
                  v-if="relationshipsData[scope.$index].is_bidirectional"
                >
                  <span
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                  >
                    {{ relationshipsData[scope.$index].child_relation_type }}
                  </span>
                  <span
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                  >
                    {{ relationshipsData[scope.$index].parent_relation_type }}
                  </span>
                </template>
                <template v-else>
                  <b>-</b>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_entity_id"
                label="Child Entity"
                width="180"
              >
                <template
                  slot-scope="scope"
                  v-if="relationshipsData[scope.$index].is_bidirectional"
                >
                  <el-select
                    v-model="relationshipsData[scope.$index].child_entity_id"
                    disabled
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>

                  <el-select
                    v-model="relationshipsData[scope.$index].parent_entity_id"
                    disabled
                    v-if="
                      relationshipsData[scope.$index].owner_type == 'PARENT'
                    "
                  >
                    <el-option
                      v-for="(entity, index) of allEntities"
                      :label="entity.name"
                      :key="index"
                      :value="entity._id"
                    ></el-option>
                  </el-select>
                </template>
                <template v-else>
                  <b>-</b>
                </template>
              </el-table-column>

              <el-table-column
                prop="child_relation_title"
                label="As"
                width="100"
              >
                <template
                  slot-scope="scope"
                  v-if="relationshipsData[scope.$index].is_bidirectional"
                >
                  <span
                    v-if="relationshipsData[scope.$index].owner_type == 'CHILD'"
                    class="truncate-text"
                  >
                    {{ relationshipsData[scope.$index].child_relation_title }}
                  </span>
                  <el-tooltip
                    class="el-tooltips"
                    :content="
                      relationshipsData[scope.$index].parent_relation_title
                    "
                    placement="top"
                  >
                    <span
                      v-if="
                        relationshipsData[scope.$index].owner_type == 'PARENT'
                      "
                      class="truncate-text"
                    >
                      {{
                        relationshipsData[scope.$index].parent_relation_title
                          | truncate(8, "...")
                      }}
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>
                  <b>-</b>
                </template>
              </el-table-column>
              <el-table-column
                prop="parent_relation_title"
                label="Actions"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-delete"
                    @click="deleteRelationship(scope.row._id)"
                    type="danger"
                    size="mini"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-row>
    <div class="actions mt-10" v-if="activeStep == 4" id="main-playboard">
      <EntityActions :currentEntity="currentEntity" :saveClicked="saveClicked">
      </EntityActions>
    </div>
    <div class="view-configuration" v-if="activeStep == 3">
      <EntityCustomizations
        :currentEntity="currentEntity"
        @refresh="fetchEntityId"
      ></EntityCustomizations>
    </div>
    <dialog-component
      :title="'Entity Information'"
      :visible="newEntityModal"
      :containerWidth="getIsMobile ? '100%' : '65%'"
      class="doc-modal entity-information-popup"
      v-loading="modalLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @before-close="destroyCreateModal"
    >
      <div
        v-loading="docLoading"
        element-loading-text="Generating document template, please wait..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="scroll">
          <span class="content-spacing"
            ><br />
            <label>Name :</label>
            <el-input
              type="text"
              placeholder="Title"
              v-model="entityName"
              size="mini"
            ></el-input
            ><br />
            <!-- <p class="error" v-if="getError">
            {{ getError }}
          </p>-->
          </span>
          <br />
          <span class="content-spacing">
            <label>Description :</label>
            <el-input
              type="textarea"
              placeholder="Description"
              v-model="description"
              size="mini"
            ></el-input>
            <!-- <p class="error" v-if="getError">
            {{ getError }}
          </p>-->
          </span>
          <br />
          <br />
          <span class="mt-1 content-spacing">
            <el-row>
              <el-col :span="8">
                <label>Printing settings :</label>
                <el-select
                  placeholder="Document template"
                  v-model="printingDocuments"
                  multiple
                  size="mini"
                  clearable
                  collapse-tags
                  style="width: 90%"
                >
                  <el-option
                    v-for="(doc, i) in allDocumentTemplates"
                    :key="i + '_document'"
                    :label="doc.title"
                    :value="doc.configurable_document_id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <label>Default document :</label>
                <el-select
                  placeholder="Default document template"
                  v-model="defaultPrintingDocument"
                  size="mini"
                  clearable
                  style="width: 90%"
                >
                  <el-option
                    v-for="(doc, i) in allDocumentTemplates.filter(
                      (e) =>
                        printingDocuments &&
                        printingDocuments.indexOf(
                          e.configurable_document_id
                        ) !== -1
                    )"
                    :key="i + '_document_default'"
                    :label="doc.title"
                    :value="doc.configurable_document_id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <label>Export Entity Document :</label>
                <el-select
                  placeholder="Export Entity Document Template"
                  v-model="exportPrintingDocument"
                  size="mini"
                  clearable
                  style="width: 90%"
                >
                  <el-option
                    v-for="(doc, i) in allDocumentTemplates.filter(
                      (e) =>
                        printingDocuments &&
                        printingDocuments.indexOf(
                          e.configurable_document_id
                        ) !== -1
                    )"
                    :key="i + '_document_export'"
                    :label="doc.title"
                    :value="doc.configurable_document_id"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </span>
          <br />
          <div class="mt-1">
            <i class="el-icon-info"></i
            ><label>
              To print the entity data in a document, please click on the
              "Generate Document Template" button or select a existed document
              template in the above printing settings.</label
            >
            <el-button
              @click="createDocumentTemplate"
              type="primary"
              class="mt-1"
              size="mini"
            >
              Generate Document template
            </el-button>
          </div>
          <div
            class="mb-4 mt-1"
            v-if="getCustomMsgData && defaultPrintingDocument"
          >
            <label>Customized Document Name:</label>
            <div style="position: relative">
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border: 1px solid black;
                  z-index: 1;
                "
              ></div>
              <CustomEntityMention
                :entity_id="entityId"
                :data="getCustomMsgData"
                :fromPdfPrinting="true"
                :allTemplates="allTemplates"
                style="position: relative; z-index: 2"
              ></CustomEntityMention>
            </div>
          </div>
          <div class="mt-1">
            <el-row>
              <el-col :span="8">
                <label>Default email template :</label>
                <el-select
                  placeholder="email template"
                  v-model="defaultEmailTemplate"
                  size="mini"
                  clearable
                  collapse-tags
                  style="width: 90%"
                >
                  <el-option
                    v-for="(doc, i) in getEmailTemplates('ENTITY')"
                    :key="i + '_document'"
                    :label="doc.name"
                    :value="doc._id"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <label>Default header footer template :</label>
                <el-select
                  placeholder="email template"
                  v-model="defaultParentTemplate"
                  size="mini"
                  clearable
                  collapse-tags
                  style="width: 90%"
                >
                  <el-option
                    v-for="(doc, i) in getEmailTemplates('PARENT')"
                    :key="i + '_document'"
                    :label="doc.name"
                    :value="doc._id"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </div>
          <br />
          <el-row>
            <el-col :span="12">
              <span class="content-spacing"
                ><br />
                <label>Entity Type</label>
                <el-radio-group
                  v-model="entity_type"
                  size="mini"
                  fill="#323b6a"
                  style="margin-top: 0.5rem; margin-left: 0.5rem"
                  class="entity-type-radio"
                  :disabled="hasData"
                  @change="checkIndividualEntity"
                >
                  <el-radio-button
                    label="GENERAL"
                    @click.native="isAccount = false"
                  ></el-radio-button>
                  <el-radio-button
                    label="BUSINESS"
                    @click.native="isAccount = true"
                  ></el-radio-button>
                  <el-radio-button
                    label="INDIVIDUAL"
                    @click.native="isAccount = true"
                  ></el-radio-button>
                </el-radio-group>
                <br />
                <span v-if="hasData" style="font-size: 12px; color: #e6a23c"
                  >Can't change entity type, some {{ entityName }} data already
                  existed.</span
                >
              </span>
            </el-col>
            <el-col :span="12" v-if="!this.$route.query.group">
              <span class="content-spacing"
                ><br />
                <label>Groups:</label>
                <el-row>
                  <el-col :span="12">
                    <el-select
                      v-model="entityGroupsData"
                      placeholder="Select Group"
                      multiple
                      filterable
                      collapse-tags
                      @change="checkSelection"
                    >
                      <el-option
                        v-for="(item, index) of getAllEntityGroupsData"
                        v-bind:key="index"
                        :label="item.name"
                        :value="item._id"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1">
                    <el-tooltip
                      content="Add New Group"
                      placement="top"
                      effect="light"
                    >
                      <i
                        class="el-icon-circle-plus-outline"
                        @click="addGroupPopUP"
                      ></i>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <span v-if="!isValidSelection" style="color: red"
                  >Please select at least one group!</span
                >
              </span>
            </el-col>
          </el-row>
          <br />
          <hr />
          <el-row :gutter="30" v-if="entity_type !== 'INDIVIDUAL'">
            <el-col :span="12">
              <label>QR Scan Settings</label>
              <el-select
                placeholder="QR code main field"
                v-model="qrCodeImportField"
                :filterable="true"
                :clearable="true"
                style="width: 100%"
                size="mini"
              >
                <el-option
                  v-for="(item, index) of allEntityFields.filter(
                    (e) =>
                      e.input_type == 'ENTITY' && e.properties.qr_code_scanning
                  )"
                  v-bind:key="item.key + '_' + index"
                  :label="item.template_name + '-' + item.label"
                  :value="item.template_key"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <label>QR Value Match With</label> <br />
              <el-radio-group
                v-model="qrCodeImportField_search_type"
                size="mini"
              >
                <el-radio-button label="Record Id"></el-radio-button>
                <el-radio-button label="Fields"></el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col :span="4" v-if="qrCodeImportField_search_type == 'Fields'">
              <label>QR Scan Settings</label>
              <el-select
                placeholder="QR code main field"
                v-model="qrCodeImportField_search_field"
                :filterable="true"
                :clearable="true"
                style="width: 100%"
                size="mini"
              >
                <el-option
                  v-for="(item, index) of allEntityFields.find(
                    (e) =>
                      e.input_type == 'ENTITY' &&
                      e.properties.qr_code_scanning &&
                      e.template_key == this.qrCodeImportField
                  )?.primary_fields || []"
                  v-bind:key="item + '_' + index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>

              <!-- this.allEntityFields.filter(
                    (e) =>
                      e.input_type == 'ENTITY' && e.properties.qr_code_scanning && e.id==
                  ) -->
            </el-col>
          </el-row>
          <el-row class="mt-1 mb-1">
            <div v-if="entity_type !== 'INDIVIDUAL' && qrCodeImportField">
              <div>
                <div>
                  <span>Success title :</span>
                  <el-input
                    v-model="qrCodeImportField_success_title"
                    class="mr-2 ml-6"
                    :style="getIsMobile ? 'width:300px' : 'width: 345px'"
                    placeholder="Enter Title"
                  ></el-input>
                </div>
                <div class="mt-1">
                  <span>Success Message :</span>
                  <el-input
                    v-model="qrCodeImportField_custom_message"
                    type="textarea"
                    class="mr-2 ml-2"
                    :style="getIsMobile ? 'width:300px' : 'width: 345px'"
                    maxlength="998"
                    rows="2"
                    show-word-limit
                    placeholder="Enter message"
                  ></el-input>
                  <el-select
                    v-model="copyField"
                    placeholder="Select Field to copy"
                    filterable
                    collapse-tags
                    @change="copyURL"
                    :style="
                      getIsMobile
                        ? 'width:300px;margin-top:5px'
                        : 'width: 350px'
                    "
                    class="Select-in-Email-Templates"
                  >
                    <el-option
                      v-for="(item, index) of getValidFields()"
                      v-bind:key="index"
                      :label="item.label"
                      :value="item.label"
                      :style="
                        getIsMobile ? 'width: 300px;margin-left: 10rem' : ''
                      "
                    >
                      {{ item.label }} ({{ item.input_type }})
                      <a
                        @click.stop="pushToErrorMessage(item.label, 'success')"
                      >
                        <i class="el-icon-right"></i>
                      </a>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="mt-2">
                <div>
                  <span>Error Title :</span>
                  <el-input
                    v-model="qrCodeImportField_error_title"
                    class="mr-2 ml-6"
                    :style="getIsMobile ? 'width:300px' : 'width: 345px'"
                    placeholder="Enter Title"
                  ></el-input>
                </div>
                <div class="mt-1">
                  <span>Error Message :</span>
                  <el-input
                    v-model="qrCodeImportField_error_message"
                    type="textarea"
                    class="mr-2 ml-4"
                    :style="getIsMobile ? 'width:300px' : 'width: 345px'"
                    maxlength="998"
                    rows="2"
                    show-word-limit
                    placeholder="Enter message"
                  ></el-input>
                  <el-select
                    v-model="copyField"
                    placeholder="Select Field to copy"
                    filterable
                    collapse-tags
                    @change="copyURL"
                    :style="
                      getIsMobile
                        ? 'width:300px;margin-top:5px'
                        : 'width: 350px'
                    "
                    class="Select-in-Email-Templates"
                  >
                    <el-option
                      v-for="(item, index) of getValidFields()"
                      v-bind:key="index"
                      :label="item.label"
                      :value="item.label"
                      :style="
                        getIsMobile ? 'width: 300px;margin-left: 10rem' : ''
                      "
                    >
                      {{ item.label }} ({{ item.input_type }})
                      <a @click.stop="pushToErrorMessage(item.label)">
                        <i class="el-icon-right"></i>
                      </a>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-row>
          <br />
          <el-row
            v-if="entity_type == 'INDIVIDUAL' || entity_type == 'BUSINESS'"
            :gutter="30"
            class="mb-2"
          >
            <el-col :span="24">
              <el-checkbox v-model="checkedHasLogin"
                >This entity has login for all its contacts</el-checkbox
              >
            </el-col>
            <el-col :span="12" v-if="checkedHasLogin">
              <div>
                Application user alias
                <el-input
                  v-model="receiverAlias"
                  placeholder="Enter alias"
                  size="mini"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="12" v-if="checkedHasLogin">
              <div>
                Whom can login?
                <el-select
                  placeholder="Select filter"
                  v-model="receiverFilter"
                  :filterable="true"
                  :clearable="true"
                  style="width: 100%"
                  size="mini"
                >
                  <el-option
                    v-for="(filter, index) of entityFilters"
                    :key="index + '_filter'"
                    :label="filter.filter_name"
                    :value="filter._id"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="mt-1">
                <el-checkbox
                  v-model="isTabSwitchingAlert"
                  placeholder="Enter alias"
                  size="mini"
                  >Enable Template Saving Alert.</el-checkbox
                >
              </div>
              <div
                v-if="
                  (currentEntityAllFields || []).some(
                    (doc) => doc.inputType === 'FILE'
                  )
                "
              >
                <div class="mt-1">
                  <el-checkbox v-model="downloadDocuments" size="mini">
                    Download Entity Documents
                  </el-checkbox>
                </div>
                <el-col v-if="downloadDocuments" class="mt-2">
                  <el-select
                    v-model="selectedDocuments"
                    placeholder="Select Documents"
                    multiple
                    size="mini"
                    collapse-tags
                  >
                    <el-option
                      v-for="doc in (currentEntityAllFields || []).filter(
                        (doc) => doc.inputType === 'FILE'
                      )"
                      :key="`${doc.template_id}#${doc.template_key}`"
                      :label="doc.label"
                      :value="doc.template_key"
                    />
                  </el-select>
                </el-col>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelUpdate" size="mini">Cancel</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleUpdate"
          :disabled="!entityName"
          >Update</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="'Add New Group'"
      :visible="showAddGroupDialog"
      @before-close="showAddGroupDialog = false"
      :containerWidth="getIsMobile ? '100%' : '30%'"
      :isShowFooter="false"
    >
      <el-form
        class="m-tb-2"
        :model="entitiesListForm"
        ref="entitiesListForm"
        :rules="rules"
        @submit.native.prevent="saveGroup"
      >
        <el-form-item label="Group Title" prop="groupName">
          <el-input
            type="text"
            v-model="entitiesListForm.groupName"
            placeholder="Enter Title"
            :style="{ height: '50px' }"
          ></el-input>
        </el-form-item>

        <el-form-item class="float-right">
          <el-button
            class="btn btn-outline-danger btn-sm m-lr-1"
            type="button"
            @click="showAddGroupDialog = false"
            :disabled="loadingSaveGroup"
          >
            <i class="fa fa-close"></i> Cancel
          </el-button>

          <el-button
            class="btn btn-outline-primary btn-sm m-lr-1"
            type="primary"
            @click="saveGroup"
            :disabled="loadingSaveGroup || !entitiesListForm.groupName.trim()"
          >
            <i v-if="loadingSaveGroup" class="fa fa-spinner fa-spin"></i>
            <i v-else class="fa fa-check-square-o"></i>
            {{ loadingSaveGroup ? "Saving..." : "Save Group" }}
          </el-button>
        </el-form-item>
      </el-form>
    </dialog-component>
    <dialog-component
      :visible="goBackToEntities"
      :title="'Warning'"
      :containerWidth="getIsMobile ? '100%' : '40%'"
      @before-close="goBackToEntities"
    >
      <div class="Back-in-Entity">
        <h5 class="fs-6">
          At least one template with primary field is required to project for
          the entity information.!
        </h5>
        <p class="fs-7 mt-1" style="margin-bottom: -33px">
          Are you sure to exit ?
        </p>
      </div>
      <span slot="footer">
        <el-button type="plain" @click="goBackToEntities = false" size="mini"
          >No</el-button
        >
        <el-button type="primary" @click="backToEntities" size="mini"
          >Yes</el-button
        >
      </span>
    </dialog-component>

    <el-dialog
      :visible.sync="showOrphanFieldsError"
      title="Warning"
      :width="getIsMobile ? '100%' : '50%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <ul>
          <li v-for="(el, index) of orphanFields" :key="index">
            {{ index + 1 }}. {{ el.related_reason }} <b>{{ el.field.label }}</b
            >, The Related fields are
            <b>{{ el.related_fields.map((e) => e.label).join(", ") }}</b>
          </li>
        </ul>
      </div>
      <span slot="footer">
        <el-divider></el-divider>
        <el-button
          type="primary"
          size="mini"
          @click="showOrphanFieldsError = false"
          >Ok</el-button
        >
      </span>
    </el-dialog>
    <dialog-component
      :title="'Add Limit to ' + newRelationship.child_relation_title"
      :visible="limitPopup"
      :containerWidth="'27%'"
      @before-close="limitPopup = false"
      :isShowFooter="false"
    >
      <div class="mb-2 mt-4">
        <el-radio-group v-model="newRelationship.limit_type">
          <el-radio label="value">Value</el-radio>
          <el-radio label="field">Field</el-radio>
        </el-radio-group>
        <br /><br />
        <el-input-number
          placeholder="Enter limit"
          v-if="newRelationship.limit_type == 'value'"
          style="width: 100%"
          v-model="newRelationship.limit_value"
        ></el-input-number>
        <el-select
          placeholder="Select a Number Field"
          v-if="newRelationship.limit_type == 'field'"
          v-model="newRelationship.limit_field"
          filterable
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="(num, index) in parentEntityNumberFields"
            :key="index"
            :value="num.template_key"
            :label="num.label"
          >
          </el-option>
        </el-select>
        <p style="margin-top: 20px; margin-bottom: 5px">Warning message</p>
        <el-input
          v-model="newRelationship.warning_message"
          placeholder="Enter warning message"
        ></el-input>
      </div>
      <span>
        <el-button
          class="mb-1"
          type="primary"
          style="float: right"
          size="mini"
          @click="addRelationship(editIndex)"
          :disabled="disableAddLimit()"
          >Add Limit</el-button
        >
        <el-button
          style="float: right; margin-right: 10px"
          size="mini"
          @click="addRelationship(editIndex, true)"
          >Skip</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import CompanyDocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
// import EntityCustomizations from "./EntityCustomizations.vue";

import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import {
  updateEntityData,
  fetchEntitiesByPagination,
  addSingleEntityToLocal,
} from "@/repo/entityRepo";
import { fetchActiveEntityCustomizationsByEntity } from "@/repo/entityCustomizationRepo";
import { fetchGroups, addSingleGroupToLocal } from "@/repo/groupsRepo";
import { postAPICall } from "../../helpers/httpHelper";
import { fetchEntityRelationships } from "@/repo/entityRelationshipsRepo";
export default {
  name: "Entity-AddEditEntity",
  components: {
    DropTemplates: () => import("./DropTemplates"),
    draggable: () => import("vuedraggable"),
    EntityActions: () => import("./EntityActions"),
    EntityCustomizations: () => import("./EntityCustomizations"),
    CustomEntityMention: () =>
      import("@/components/widgets/entityMention/index.vue"),
  },
  mixins: [
    MobileRelatedHelper,
    CompanyDocumentsHelper,
    NavigationHelper,
    EntityHelper,
  ],
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
      this.$store.commit("navigationOpen/setIsRSOpen", false, {
        root: true,
      });
    }
    bus.$on("tabsConfigStatus", (status) => {
      this.hasUnsavedChanges = status;
    });
  },

  data() {
    return {
      isTabSwitchingAlert: "",
      downloadDocuments: "",
      selectedDocuments: [],
      entitiesListForm: {
        groupName: "",
      },
      rules: {
        groupName: [
          {
            required: true,
            message: "Please input the group name",
            trigger: "blur",
          },
        ],
      },
      selectedFieldId: "",
      hasUnsavedChanges: false,
      isValidSelection: true,
      activeStep: -1,
      loading: false,
      loadingText: "Loading...",
      entityName: "",
      originalEntityName: "",
      description: "",
      printingDocuments: [],
      defaultPrintingDocument: "",
      exportPrintingDocument: "",
      allDocumentTemplates: [],
      modalLoading: false,
      goBackToEntities: false,
      isEdit: false,
      allTemplates: [],
      selectedEntity: [],
      isUpdate: false,
      primaryFields: [],
      editable: true,
      isDragging: false,
      delayedDragging: false,
      allFields: [],
      newEntityModal: false,
      isAccount: false,
      entity_type: "GENERAL",
      allEntitytemplateData: [],
      isSubmit: false,
      isSave: false,
      showAddGroupDialog: false,
      loadingSaveGroup: false,
      entityGroupsData: [],
      allEntities: [],
      otherEntities: [],
      relationshipsData: [],
      entityId: [],
      allEmailTemplates: [],
      defaultEmailTemplate: "",
      defaultParentTemplate: "",
      newRelationship: {
        parent_entity_id: "",
        child_entity_id: "",
        child_relation_title: "",
        child_relation_type: "MANY",
        parent_relation_title: "",
        parent_relation_type: "ONE",
        is_bidirectional: true,
        representation: "TAB",
        limit_type: "value",
        limit_value: null,
        limit_field: "",
        warning_message: "You have reached the limit !",
      },
      newRelationshipsData: [],
      orphanFields: [],
      showOrphanFieldsError: false,
      checkedHasLogin: true,
      receiverAlias: "",
      receiverFilter: "",
      hasData: false,
      currentEntity: null,
      saveClicked: false,
      limitPopup: false,
      editIndex: -1,
      parentEntityNumberFields: [],
      entityFilters: [],
      standardTemplate: null,
      getAllEntityGroupsData: [],
      tempEntity: null,
      firstTemplateId: "",
      qrCodeImportField: "",
      qrCodeImportField_search_type: "Record Id",
      qrCodeImportField_search_field: "",
      qrCodeImportField_custom_message: "Data has been added!",
      qrCodeImportField_error_message: "Error while adding data!",
      qrCodeImportField_success_title: "Added!",
      qrCodeImportField_error_title: "Failed!",
      currentEntityAllFields: null,
      copyField: "",
      allEntityFields: [],
      docLoading: false,
      customization: {},
      previousTemplates: [],
      fetchedRelationships: null,
      pdfData: {
        custom_message: "",
      },
    };
  },
  computed: {
    getCustomMsgData() {
      let pdfDataCopy = { ...this.pdfData };
      if (!pdfDataCopy?.custom_message) {
        pdfDataCopy.custom_message = this.currentEntity?.name
          ? this.currentEntity.name
          : "";
      }
      return pdfDataCopy;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    draggableFields() {
      return this.allFields.filter((field) => field.type !== "CONTENT");
    },
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
      "getIsRSOpen",
    ]),
    // ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entities", [
      "getEntityCreatedStatus",
      "getEntityCreatedData",

      "getEntityDataById",
      "getEntityDataUpdateStatus",
      "getEntityErrors",
      // "getAllEntities",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("templatesData", ["getTemplateDataByentityId"]),
    ...mapGetters("entityGroups", [
      // "getAllEntityGroupsData",
      "getSingleEntityGroupData",
      "getEntityGroupErrors",
      "getError",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationshipAddStatus",
      "getEntityRelationships",
      "getEntityRelationshipUpdateStatus",
      "getEntityRelationshipDeleteStatus",
    ]),

    ...mapGetters("errors", ["getError", "getErrors"]),
    ...mapGetters("documents", ["getAllTemplates"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
  },
  async mounted() {
    this.checkIsEdit();
    await Promise.all([
      this.fetchTemplates(),
      this.fetchEntityId(),
      this.fetchEntityData(),
      this.fetchAllCompanyTemplates(),
      this.fetchCompanyGroups(),
      this.fetchEmailTemplates(),
      // this.fetchHeaderFooterTemplates(),
      this.fetchActiveCustomization(),
    ]);
    let [allFields] = await Promise.all([
      this.fetchEntityDetails(this.getEntityDataById?._id, true, true),
      this.fecthEntityData(),
    ]);
    this.currentEntityAllFields = allFields;
  },
  methods: {
    handleUpdate() {
      if (
        this.downloadDocuments &&
        (!this.selectedDocuments || this.selectedDocuments.length === 0)
      ) {
        this.$message.error("Please select the documents to download.");
        return;
      }
      this.newEntityModal = false;
    },
    async fetchActiveCustomization() {
      let response = await fetchActiveEntityCustomizationsByEntity(
        this.$route.params.entity_id
      );
      if (response) {
        this.customization = { ...response };
      }
    },
    checkSelection() {
      this.isValidSelection = this.entityGroupsData.length > 0;
    },
    getEmailTemplates(type) {
      return this.allEmailTemplates.filter((temp) => temp.type == type);
    },
    getValidFields() {
      return [
        ...(this.currentEntityAllFields || []).filter(
          (fd) =>
            fd.type != "CONTENT" &&
            !["DATA_TABLE", "ENTITY_TABLE"].includes(fd.inputType)
        ),
      ];
    },
    pushToErrorMessage(field, type) {
      if (type == "success") {
        this.qrCodeImportField_custom_message =
          this.qrCodeImportField_custom_message + ` [[${field}]]`;
      } else {
        this.qrCodeImportField_error_message =
          this.qrCodeImportField_error_message + ` [[${field}]]`;
      }
      this.$message({
        message: `${field} added to the message`,
        type: "success",
      });
    },
    copyURL(field) {
      navigator.clipboard.writeText(`[[${field}]]`);
      this.$message({
        message: "Field Copied!",
        type: "success",
      });
    },
    checkIndividualEntity(v1) {
      if (v1 == "INDIVIDUAL") {
        let checkIndividualEntity = this.selectedEntity.find(
          (t) => t?.template_id == this.standardTemplate._id
        );
        if (!checkIndividualEntity) {
          this.selectedEntity.unshift({
            is_repeatable: false,
            is_public: true,
            max_records: 1,
            isActive: true,
            default_display: "TABLE",
            template_order: 1,
            receiverAlias: "",
            senderAlias: "",
            template_id: this.standardTemplate._id,
            templateInfo: this.standardTemplate,
            downloadDocuments: "",
            selectedDocuments: [],
          });
        }
      } else {
        let checkIndividualEntity = this.selectedEntity.findIndex(
          (t) => t?.template_id == this.standardTemplate._id
        );
        if (checkIndividualEntity > -1) {
          this.selectedEntity.splice(checkIndividualEntity, 1);
        }
      }
    },
    async openNewEntityModal() {
      if (this.$route?.params?.entity_id) {
        await this.getEntityFilters(this.$route.params.entity_id);
      }
      if (!this.receiverAlias) {
        this.receiverAlias = this.entityName;
      }
      this.originalEntityName = this.entityName;
      this.newEntityModal = true;
      this.allEntityFields = await this.fetchEntityDetails(
        this.$route.params.entity_id,
        false,
        false,
        false,
        true,
        true,
        true
      );
      if (!this.allEntityFields?.length) {
        this.updateEntity();
      }
    },
    async cancelUpdate() {
      if (this.$route?.params?.entity_id) {
        await this.getEntityFilters(this.$route.params.entity_id);
      }
      if (!this.receiverAlias) {
        this.receiverAlias = this.entityName;
      }
      this.entityName = this.originalEntityName;
      this.description = "";
      this.printingDocuments = "";
      this.defaultPrintingDocument = "";
      this.exportPrintingDocument = "";
      this.defaultEmailTemplate = "";
      this.defaultParentTemplate = "";
      this.entityGroupsData = "";
      this.newEntityModal = false;
    },
    async getEntityFilters(entity_id) {
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.entityFilters = [];
      if (this.getAllEntityFilters) {
        this.entityFilters = this.getAllEntityFilters;
      }
    },
    async fetchAllCompanyTemplates() {
      await this.$store.dispatch("documents/fetchAllTemplates", {
        get_all: true,
      });
      this.allDocumentTemplates =
        this.getAllTemplates && this.getAllTemplates.data
          ? this.getAllTemplates.data
          : [];
    },
    async fetchEmailTemplates() {
      let params = {
        page: 1,
        limit: 40,
        entity_id: this.$route.params.entity_id,
        getAll: true,
        include_parent: true,
      };
      let response = await postAPICall("GET", "email-template/list", params);
      this.allEmailTemplates = response.data;
    },
    async fetchEntityData() {
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", {
        entity_id: this.$route.params.entity_id,
        template_fields_data: [],
        filters: [],
        relationship: this.realtionshipFilter,
        limit: 1,
        page: 1,
      });
      if (
        this.getEntityRecordsForTable?.data &&
        this.getEntityRecordsForTable.data.length
      ) {
        this.hasData = true;
      }
    },
    updatedPrimaryFields(key) {
      this.primaryFields = this.primaryFields.map((e) => {
        if (key != e.key) {
          e.is_primary = false;
        }

        return e;
      });
    },
    async backToEntities() {
      let query = this.getNavigationQuery(this.$route.query);
      let UrlPath = "";
      UrlPath = "/entity/";
      this.$router.push({
        path: UrlPath,
        query: query,
      });
      this.goBackToEntities = false;
    },
    /*  ***Fetch Groups  start  **** */
    async fetchCompanyGroups() {
      try {
        const groups = await fetchGroups({
          get_all: true,
        });
        if (groups?.data) {
          this.getAllEntityGroupsData = [...groups.data];
        }
      } catch (e) {
        console.log("fetchCompanyGroups", e);
      }
    },
    /*  ***Fetch Groups  end  **** */
    async addGroupPopUP() {
      this.showAddGroupDialog = true;

      await this.$store.dispatch("entityGroups/resetErrors");
    },
    async saveGroup() {
      this.loadingSaveGroup = true;
      await this.$store.dispatch("entityGroups/addEntityGroup", {
        name: this.entitiesListForm.groupName,
        description: "",
        entities: [],
      });
      if (
        this.getSingleEntityGroupData &&
        this.getSingleEntityGroupData.success
      ) {
        await addSingleGroupToLocal(this.getSingleEntityGroupData.data);
        this.fetchCompanyGroups();
        this.loadingSaveGroup = false;
        this.entitiesListForm.groupName = "";
        this.showAddGroupDialog = false;
        this.entityGroupsData.push(this.getSingleEntityGroupData.data._id);
      } else if (this.getError && !this.getError.success) {
        this.loadingSaveGroup = false;
        let msg =
          this.getError && this.getError.message
            ? this.getError.message
            : "Something went wrong";
        this.$message.warning(msg);
      }
    },

    updateEntityTemplates(templates) {
      if (templates) {
        this.selectedEntity = templates;
        if (this.isSave) {
          this.createEntity();
        } else if (this.isUpdate) {
          this.updateEntity();
        }
      }
    },
    destroyCreateModal() {
      this.newEntityModal = false;
    },

    checkEntitiesParentTitle(id) {
      let entity = this.otherEntities.find((x) => x._id == id);
      let currentEntity = this.entityName;

      if (entity.name && !this.newRelationship.child_relation_title) {
        this.newRelationship.child_relation_title = entity.name;
      }
      if (currentEntity && !this.newRelationship.parent_relation_title) {
        this.newRelationship.parent_relation_title = currentEntity;
      }
    },

    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      if (draggedElement.type === "CONTENT") {
        return false;
      }
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    gotoBuilder() {
      this.activeStep = 0;
    },
    checkIsEdit() {
      if (
        this.$route &&
        this.$route.name &&
        this.$route.name == "Edit Entity"
      ) {
        this.isEdit = true;
        this.entityId = this.$route.params.entity_id;
      } else {
        if (this.$route && this.$route.query && this.$route.query.name) {
          this.entityName = this.$route.query.name;
        }
      }
    },

    async updateEntity(close = false) {
      this.loading = true;
      if (this.entityName) {
        // if (
        //   this.entityGroupsData &&
        //   this.entityGroupsData.length
        // ) {
        if (this.selectedEntity && this.selectedEntity.length) {
          if (this.activeStep === 1) {
            if (!this.primaryFields.length) {
              this.$message.warning(
                "At least one Primary Field is required to project for the entity information"
              );
              this.loading = false;
            } else {
              this.update(close);
            }
          } else {
            this.update(close);
          }
        } else {
          this.isSubmit = false;
          this.$message.warning("Please add at least one template");

          this.loading = false;
        }
        // } else {
        //   this.isSubmit = false;
        //  this.$message.warning("Please choose at least one Group");
        //   this.loading = false;
        // }
      } else {
        this.isSubmit = false;
        this.$message.warning("Entity name required");
        this.loading = false;
      }
    },
    async updateLocalEntityData(id, params) {
      let currentEntity = JSON.parse(
        JSON.stringify({ ...this.tempEntity, ...params })
      );
      this.tempEntity = {
        ...currentEntity,
        ...{
          templates: currentEntity.templates.map((t) => {
            if (t?.templateInfo) {
              delete t.templateInfo;
            }
            return t;
          }),
        },
      };
      updateEntityData(id, this.tempEntity);
    },
    async fetchEntityId(setTab = 0) {
      this.modalLoading = true;
      let id = this.$route.params.entity_id;
      this.currentEntity = null;
      await this.$store.dispatch("entities/fetchEntityById", { entity_id: id });
      if (this.getEntityDataById) {
        this.firstTemplateId = this.getEntityDataById.templates[0]?.template_id;
        this.currentEntity = this.getEntityDataById;
        this.entityName = this.getEntityDataById.name;
        this.description = this.getEntityDataById.description;
        this.primaryFields = this.getEntityDataById.primaryFields;
        this.isAccount = this.getEntityDataById.isAccount;
        this.entity_type = this.getEntityDataById.entity_type;
        this.entityGroupsData = this.getEntityDataById.entity_groups;
        this.checkedHasLogin = this.getEntityDataById.has_login;
        this.isTabSwitchingAlert =
          this.getEntityDataById.is_tab_switching_alert;
        this.downloadDocuments = this.getEntityDataById.isDownloadDocuments;
        this.selectedDocuments = this.getEntityDataById.isSelectedDocuments;
        this.receiverAlias = this.getEntityDataById.receiver_alias;
        this.receiverFilter = this.getEntityDataById.receiver_filter;
        this.printingDocuments = this.getEntityDataById.printing_documents;
        this.defaultEmailTemplate =
          this.getEntityDataById.default_email_template;
        this.defaultParentTemplate =
          this.getEntityDataById.default_parent_template;
        this.qrCodeImportField = this.getEntityDataById.qr_code_import_field;
        this.qrCodeImportField_search_type =
          this.getEntityDataById.qrCodeImportField_search_type;
        this.qrCodeImportField_search_field =
          this.getEntityDataById.qrCodeImportField_search_field;
        this.qrCodeImportField_custom_message =
          this.getEntityDataById.qrCodeImportField_custom_message ||
          "Data has been added!";
        this.qrCodeImportField_error_message =
          this.getEntityDataById.qrCodeImportField_error_message ||
          "Error adding data!";
        this.qrCodeImportField_success_title =
          this.getEntityDataById.qrCodeImportField_success_title || "Added";
        this.qrCodeImportField_error_title =
          this.getEntityDataById.qrCodeImportField_error_title || "Failed";
        this.defaultPrintingDocument =
          this.getEntityDataById.default_printing_document;
        this.exportPrintingDocument =
          this.getEntityDataById.export_printing_document;
        this.pdfData = this.getEntityDataById.pdf_printing_info;
        this.setSelectedTemplates(setTab);
        this.updateLocalEntityData(id, this.getEntityDataById);
      } else {
        this.modalLoading = false;
      }
    },
    setSelectedTemplates(setTab) {
      // let order_temp=this.getEntityDataById.templates.sort(function (a, b) { return a.template_order - b.template_order })
      let order_temp = (this.getEntityDataById?.templates || []).sort(
        (a, b) => a.template_order - b.template_order
      );
      this.activeStep = setTab;
      if (this.allTemplates) {
        this.previousTemplates = JSON.parse(JSON.stringify(order_temp));
        this.selectedEntity = order_temp;
      }
      this.modalLoading = false;
    },
    prepareParams() {
      let temp_order = this.selectedEntity.map((temp, index) => {
        temp.template_order = index + 1;
        return temp;
      });
      this.primaryFields = this.primaryFields.map((e, i) => {
        e.order = i;
        return e;
      });
      if (this.getCustomMsgData?.custom_message) {
        this.getCustomMsgData.custom_message = this.getCustomMessageWithIds(
          this.getCustomMsgData.custom_message
        );
      }

      this.checkRelationFields();

      return {
        name: this.entityName.trim(),
        description: this.description,
        entity_type: this.entity_type,
        templates: temp_order,
        id: this.$route.params.entity_id,
        primaryFields: this.primaryFields,
        entity_groups: this.entityGroupsData,
        has_login: this.checkedHasLogin,
        is_tab_switching_alert: this.isTabSwitchingAlert,
        isDownloadDocuments: this.downloadDocuments,
        isSelectedDocuments: this.selectedDocuments,
        receiver_alias: this.receiverAlias,
        receiver_filter: this.receiverFilter,
        isAccount: this.isAccount,
        printing_documents: this.printingDocuments,
        pdf_printing_info: this.getCustomMsgData,
        default_printing_document: this.defaultPrintingDocument,
        export_printing_document: this.exportPrintingDocument,
        default_email_template: this.defaultEmailTemplate,
        default_parent_template: this.defaultParentTemplate,
        qr_code_import_field: this.qrCodeImportField,
        qrCodeImportField_search_type: this.qrCodeImportField_search_type,
        qrCodeImportField_search_field: this.qrCodeImportField_search_field,
        qrCodeImportField_custom_message: this.qrCodeImportField_custom_message,
        qrCodeImportField_error_message: this.qrCodeImportField_error_message,
        qrCodeImportField_success_title: this.qrCodeImportField_success_title,
        qrCodeImportField_error_title: this.qrCodeImportField_error_title,
      };
      // if (this.isEdit) {
      //   data.id = this.$route.params.entity_id;
      // }
      // return data;
    },
    getCustomMessageWithIds(message) {
      for (let temp of this.allTemplates) {
        let regex = new RegExp(temp.slug, "g");
        if (message.match(regex)) {
          message = message.replace(regex, temp._id);
          break;
        }
      }
      return message;
    },
    checkRelationFields() {
      this.showOrphanFieldsError = false;

      this.orphanFields = [];

      let allFieldsMap = {};

      this.allFields.forEach((f) => {
        allFieldsMap[f.key] = f;
      });

      let primaryFieldsMap = {};
      let primaryFieldsIndexMap = {};

      this.primaryFields.forEach((f, index) => {
        primaryFieldsMap[f.key] = f;
        primaryFieldsIndexMap[f.key] = index;
      });

      this.primaryFields.forEach((e) => {
        if (e.input_type == "ENTITY") {
          let noRleatedFieds = [];
          e.dependent_fields = [];
          if (e.filters && e.filters.length) {
            e.hasRelation = false;

            for (let i = 0; i < e.filters.length; i++) {
              let filter = e.filters[i];
              let hasRelation = filter.field.indexOf("related") > -1;
              if (
                hasRelation &&
                !primaryFieldsMap[filter.value_field] &&
                allFieldsMap[filter.value_field]
              ) {
                noRleatedFieds.push(allFieldsMap[filter.value_field]);
              } else if (hasRelation && primaryFieldsMap[filter.value_field]) {
                e.dependent_fields.push(primaryFieldsMap[filter.value_field]);
              }
            }
          }

          if (noRleatedFieds.length) {
            this.orphanFields.push({
              field: e,
              related_reason: "Should Primary fields have fields Related to",
              related_fields: noRleatedFieds,
            });
          }
        } else if (e.input_type == "ENTITY_VARIABLE") {
          e.dependent_fields = [];

          if (
            !primaryFieldsMap[e.global_variable_entity_parent] &&
            allFieldsMap[e.global_variable_entity_parent]
          ) {
            this.orphanFields.push({
              field: e,
              related_reason: "Should Primary fields have fields Related to",
              related_fields: [allFieldsMap[e.global_variable_entity_parent]],
            });
          } else {
            e.dependent_fields = [
              primaryFieldsMap[e.global_variable_entity_parent],
            ];
          }
        }
      });

      if (this.allFields && this.orphanFields && this.orphanFields.length) {
        this.showOrphanFieldsError = true;
        // throw new Error(this.orphanFields);
      }
    },

    async sortTheFields() {
      let groups = [];

      let primaryFieldsIndexMap = {};

      this.primaryFields.forEach((f, index) => {
        primaryFieldsIndexMap[f.key] = index;
      });
      // let removalableIndexs = [];
      this.primaryFields.forEach((a, index) => {
        this.primaryFields.forEach((b, index2) => {
          if (index != index2) {
            if (b.dependent_fields && b.dependent_fields.length) {
              let indexes = b.dependent_fields.map(
                (f) => primaryFieldsIndexMap[f.key]
              );
              if (indexes.indexOf(index) > -1) {
                let gIndex = groups.findIndex((g) => g.indexOf(index) > -1);
                if (gIndex > -1) {
                  groups[gIndex].push(index);
                } else {
                  groups.push([index2, ...indexes]);
                }
              }
            }
          }
        });
      });

      let final = [];

      let groupedFlatten = groups.flat();
      let remaining = [...this.primaryFields];
      this.primaryFields.forEach((fled, index) => {
        if (groupedFlatten.indexOf(index) == -1) {
          final.push(fled);
          remaining.splice(index, 1);
        }
      });

      let alreadyDone = [];
      groups = groups.map((e) => {
        e = Array.from(new Set(e)).sort((a, b) => (a > b ? -1 : 1));
        e = e.filter((o) => alreadyDone.indexOf(o) == -1);
        alreadyDone = [...alreadyDone, ...e];
        let ff = e.map((l) => this.primaryFields[l]);
        return ff;
      });

      this.primaryFields = [...final, ...groups.flat()];
    },
    async builderStep(step) {
      try {
        if (this.activeStep != step) {
          this.isSubmit = false;
          this.isSave = false;
          this.isEdit = this.entityId ? true : false;
          if (this.entityName) {
            // if (
            //   this.entityGroupsData &&
            //   this.entityGroupsData.length &&
            //   this.entityGroupsData.length > 0
            // ) {
            if (!this.primaryFields.length && step != 1) {
              this.$message.warning(
                "At least one Primary Field is required to project for the entity information"
              );
              return;
            }
            if (!this.isEdit) {
              if (step == 1) {
                if (this.selectedEntity && this.selectedEntity.length) {
                  let params = this.prepareParams();
                  await this.$store.dispatch(
                    "entities/createNewEntity",
                    params
                  );
                  if (this.getEntityCreatedStatus) {
                    this.$message.success(
                      "Successfully added templates to entity"
                    );
                    this.activeStep = 1;
                    await this.generateFields();
                  }
                } else {
                  this.$message.warning("Please choose at least one template");
                }
              } else if (step == 0) {
                if (!this.primaryFields.length) {
                  this.$message.warning(
                    "At least one Primary Field is required to project for the entity information"
                  );
                } else {
                  let params = this.prepareParams();
                  await this.$store.dispatch(
                    "entities/createNewEntity",
                    params
                  );
                  if (this.getEntityCreatedStatus) {
                    this.$message.success(
                      "Successfully added primary fields to entity"
                    );
                    this.activeStep = 0;
                  }
                  this.activeStep = 0;
                }
              }
            } else {
              if (step == 1) {
                if (this.selectedEntity && this.selectedEntity.length) {
                  let params = this.prepareParams();
                  await this.$store.dispatch(
                    "entities/updateEntityById",
                    params
                  );
                  this.updateLocalEntityData(params.id, params);
                  if (this.getEntityDataUpdateStatus) {
                    this.$message.success("Successfully Updated the templates");

                    this.activeStep = 1;
                    await this.generateFields();
                  }
                } else {
                  this.$message.warning("Please choose at least one template");
                }
              } else if (step == 0) {
                if (!this.primaryFields.length) {
                  this.$message.warning(
                    "At least one Primary Field is required to project for the entity information"
                  );
                } else {
                  let params = this.prepareParams();
                  await this.$store.dispatch(
                    "entities/updateEntityById",
                    params
                  );
                  this.updateLocalEntityData(params.id, params);
                  if (this.getEntityDataUpdateStatus) {
                    this.$message.success(
                      "Successfully Updated  primary fields"
                    );
                    this.activeStep = 0;
                  }
                }
              } else if (step == 2) {
                this.activeStep = 2;
                // we have to fetch existed relationship
                await this.setEntityForRelationships();
              } else if (step == 3) {
                this.activeStep = 3;
              } else if (step == 4) {
                this.activeStep = 4;
              }
            }
            // } else {
            this.isSubmit = false;
            // this.$message.warning("Please choose at least one Group");
            //   this.loading = false;
            // }
          } else {
            this.$message.warning("Entity name required");
          }
        }
      } catch (err) {
        console.log("builderStep", err);
      }
    },

    async createDocumentTemplate() {
      let params = {
        entity_id: this.getEntityDataById._id,
      };
      if (this.getEntityDataById?.templates?.length == 0) {
        this.$message.error("Please add atleast one form template");
      } else {
        try {
          this.docLoading = true;
          let response = await postAPICall(
            "POST",
            `/entity/document-template/${this.getEntityDataById._id}`,
            params
          );

          if (response.success == true) {
            await this.fetchAllCompanyTemplates();
            let msg = response?.configurableDocument?.name
              ? response.configurableDocument.name +
                " document template has been created successfully."
              : "Document template has been created successfully.";
            this.$notify.success({
              title: "Success",
              message: msg,
            });
            this.printingDocuments.push(response.configurableDocumentId);
            this.defaultPrintingDocument = response.configurableDocumentId;
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error while creating document template",
            });
          }
          this.docLoading = false;
        } catch (err) {
          console.log("Error while creating document template", err);
        }
      }
    },

    spanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);

      if (!this.relationshipsData[rowIndex].is_bidirectional) {
        if (columnIndex === 0) {
          return [0, 4];
        } else if (columnIndex === 1) {
          return [0, 4];
        } else if (columnIndex === 3) {
          return [0, 4];
        } else if (columnIndex === 4) {
          return [0, 4];
        }
      }
    },

    async setEntityForRelationships() {
      this.loading = true;
      const [entities] = await Promise.all([
        fetchEntitiesByPagination({ get_all: true }),
        this.fetchEntityRelationships(),
      ]);
      this.allEntities = [...(entities?.data || [])];
      this.otherEntities = this.allEntities.filter(
        (x) => x._id != this.entityId
      );

      this.fetchExistedRelationships();

      this.activeStep = 2;

      this.newRelationship = {
        parent_entity_id: this.entityId,
        child_entity_id: "",
        child_relation_title: "",
        child_relation_type: "MANY",
        parent_relation_title: "",
        parent_relation_type: "ONE",
        is_bidirectional: true,
        representation: "TAB",
        apply_limit: false,
        limit_type: "value",
        limit_value: null,
        limit_field: "",
        warning_message: "You have reached the limit !",
      };
      this.loading = false;
    },

    async fetchExistedRelationships() {
      this.loading = true;
      this.loadingText = "Preparing Relationships";
      const allFields = await this.fetchEntityDetails(
        this.getEntityDataById?._id,
        false,
        false,
        false,
        true,
        true,
        true
      );
      allFields.forEach((e) => {
        if (e.inputType == "ENTITY") {
          if (
            !this.newRelationshipsData.find(
              (x) => x.child_entity_id == e.entity_id
            ) &&
            !this.relationshipsData.find(
              (x) =>
                x.parent_entity_id == e.entity_id ||
                x.child_entity_id == e.entity_id
            ) &&
            this.allEntities.find((x) => x._id == e.entity_id)
          ) {
            let relationship = {
              parent_entity_id: this.$route.params.entity_id,
              child_entity_id: e.entity_id,
              child_relation_title: e.label,
              child_relation_type: "ONE",
              parent_relation_title: this.getEntityDataById?.name
                ? this.getEntityDataById.name
                : "",
              parent_relation_type: "MANY",
              is_bidirectional: true,
              owner_type: "PARENT",
              representation: "FIELD",
              field_id: e._id,
              allow_multiple: e.allow_multiple,
              limit_type: "value",
              limit_value: null,
              limit_field: "",
              warning_message: "You have reached the limit !",
              apply_limit: false,
            };
            this.newRelationshipsData.push(relationship);
          }
        }
      });
      this.loading = false;
    },
    saveEntity() {
      if (this.activeStep === 0) {
        this.isSave = true;
        this.isSubmit = true;
      } else {
        this.createEntity();
      }
    },
    updateEntityData(close = false) {
      if (this.activeStep === 0) {
        this.isUpdate = true;
        this.isSubmit = true;
      } else {
        if (this.hasUnsavedChanges) {
          this.$message.info(
            "Please save the Tabs Configuration with the newly added modifications."
          );
        } else {
          this.updateEntity(close);
        }
      }
    },
    async createEntity() {
      if (this.entityName) {
        if (
          this.entityGroupsData &&
          this.entityGroupsData.length &&
          this.entityGroupsData.length > 0
        ) {
          if (this.selectedEntity && this.selectedEntity.length) {
            if (this.activeStep === 1) {
              if (!this.primaryFields.length) {
                this.$message.warning(
                  "At least one Primary Field is required to project for the entity information"
                );
              } else {
                this.save();
              }
            } else {
              this.save();
            }
          } else {
            this.isSubmit = false;
            this.$message.warning("Please add at least one template");
          }
        } else {
          this.isSubmit = false;
          //   this.$message.warning("Please choose at least one Group");
          this.loading = false;
        }
      } else {
        this.isSubmit = false;
        this.$message.warning("Entity name required");
      }
    },
    async save() {
      let params = this.prepareParams();
      await this.$store.dispatch("entities/createNewEntity", params);
      if (this.getEntityCreatedStatus) {
        if (this.getEntityCreatedData) {
          addSingleEntityToLocal(this.getEntityCreatedData);
        }
        this.$notify({
          title: "Success",
          message: "Successfully added the templates to entity",
          type: "success",
        });

        if (this.activeStep == 0) {
          this.activeStep = 1;
          await this.generateFields();
        } else if (this.activeStep === 1) {
          this.activeStep = 2;

          if (this.getEntityCreatedData) {
            this.entityId = this.getEntityCreatedData._id;
            await this.setEntityForRelationships();
          }

          return;
        } else if (this.activeStep === 2) {
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/entity`,
            query: query,
          });
        }
      } else {
        this.$notify({
          title: "Error",
          message: "Failed to add the templates",
          type: "error",
        });
      }
    },
    async update(close = false) {
      try {
        this.loading = true;
        let params = this.prepareParams();
        if (
          params &&
          params.id &&
          params.id == undefined &&
          this.getEntityDataById?._id
        ) {
          params.id = this.getEntityDataById._id;
        }
        let newTemps = this.selectedEntity.filter(
          (tmp) =>
            !(this.previousTemplates || [])
              .map((t) => t.template_id)
              .includes(tmp.template_id)
        );
        let delTemps = this.previousTemplates.filter(
          (tmp) =>
            !(this.selectedEntity || [])
              .map((t) => t.template_id)
              .includes(tmp.template_id)
        );
        this.previousTemplates = [
          ...JSON.parse(JSON.stringify(this.selectedEntity)),
        ];
        if (
          this.customization?.tab_groups?.length &&
          (newTemps.length || delTemps.length)
        ) {
          if (newTemps.length) {
            this.customization.tab_groups[0].tabs = [
              ...this.customization.tab_groups[0].tabs,
              ...newTemps.map((tmp) => {
                return {
                  feature_id: tmp.template_id,
                  feature_type: "TEMPLATE",
                  label: tmp.templateInfo.name,
                };
              }),
            ];
          }
          if (delTemps.length) {
            let indexesToBeDeleted = {};
            this.customization.tab_groups.map((tab, i) => {
              indexesToBeDeleted[i] = [];
              tab.tabs.map((t, j) => {
                if (
                  t.feature_type == "TEMPLATE" &&
                  delTemps.map((tmp) => tmp.template_id).includes(t.feature_id)
                ) {
                  indexesToBeDeleted[i].push(j);
                }
              });
            });
            Object.keys(indexesToBeDeleted).map((index) => {
              let indices = indexesToBeDeleted[index].sort((a, b) => b - a);
              indices.map((i) => {
                this.customization.tab_groups[index].tabs.splice(i, 1);
              });
            });
          }
          await postAPICall(
            "PUT",
            "/entity-customization/update",
            this.customization
          );
        }
        await this.$store.dispatch("entities/updateEntityById", params);
        if (this.getEntityDataUpdateStatus) {
          this.loading = false;
          this.$notify({
            title: "Success",
            message: "Entity updated successfully",
            type: "success",
          });
          this.updateLocalEntityData(params.id, params);
          if (close) {
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              path: `/entity`,
              query: query,
            });
          } else if (this.activeStep == 0) {
            this.activeStep = 1;
            await this.generateFields();
          } else if (this.activeStep === 1) {
            this.activeStep = 2;
            await this.setEntityForRelationships();
            return;
          } else if (this.activeStep === 2) {
            this.activeStep = 3;
          } else if (this.activeStep === 3) {
            this.activeStep = 4;
          } else if (this.activeStep === 4) {
            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              path: `/entity`,
              query: query,
            });
          }
        } else {
          if (this.getEntityErrors) {
            this.loading = false;
            this.$notify({
              title: "Error",
              message: "Entity name already existed.!",
              type: "error",
            });
          } else {
            this.loading = false;
            this.$notify({
              title: "Error",
              message: "Error in updating Entity",
              type: "error",
            });
          }
        }
      } catch (err) {
        this.loading = false;
        console.log("update", err);
      }
    },
    async addLimit(index) {
      this.parentEntityNumberFields = [];
      this.newRelationship = { ...this.newRelationshipsData[index] };
      if (
        this.newRelationship.representation == "FIELD" &&
        this.newRelationship.child_relation_type == "ONE" &&
        !this.newRelationship.allow_multiple
      ) {
        this.newRelationship.apply_limit = true;
        let allFields = await this.fetchEntityDetails(
          this.newRelationship.child_entity_id,
          true,
          true,
          false,
          true,
          true,
          true
        );
        this.parentEntityNumberFields = allFields.filter(
          (e) => e.inputType == "NUMBER"
        );
        this.editIndex = index;
        this.limitPopup = true;
      } else {
        this.newRelationship.apply_limit = false;
        this.addRelationship(index);
      }
    },
    async addRelationship(index, fromSkip = false) {
      try {
        delete this.newRelationship.allow_multiple;
        this.limitPopup = false;
        if (fromSkip) {
          this.newRelationship.apply_limit = false;
        }
        let status = await this.addNewRelationship();
        if (status) {
          this.newRelationshipsData.splice(index, 1);
        }
      } catch (err) {
        console.log("addRelationship", err);
      }
    },
    prepareRelationshipParams(newRelationship) {
      let params = JSON.parse(JSON.stringify(newRelationship));
      if (params.limit_value == undefined || params.limit_value == null) {
        delete params.limit_value;
      }
      return params;
    },
    async addNewRelationship() {
      this.loading = true;
      this.loadingText = "Adding Relationship";
      this.checkEntitiesParentTitle(this.newRelationship.child_entity_id);
      let params = this.prepareRelationshipParams(this.newRelationship);
      params["existedIds"] = this.relationshipsData.map((e) => e._id);
      try {
        await this.$store.dispatch(
          "entityRelationships/addEntityRelationship",
          {
            ...params,
          }
        );
        this.loading = false;

        if (this.getEntityRelationshipAddStatus) {
          this.newRelationship.child_relation_title = "";
          this.newRelationship.parent_relation_title = "";
          this.newRelationship.child_entity_id = "";
          this.$message.success("Entity Relationship added successfully");
          this.fetchEntityRelationships();
        }
        return true;
      } catch (err) {
        this.loading = false;
        console.error("addNewRelationship", err);
        throw err;
      }
    },

    async askRelationDeleteConfirm() {
      return new Promise((resolve) => {
        this.$confirm("Are you sure want to delete relationship ?", "Alert !", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },

    async deleteRelationship(id) {
      try {
        let status = await this.askRelationDeleteConfirm();

        if (status) {
          this.loading = true;

          this.loadingText = "Deleting Relationship ...";

          await this.$store.dispatch(
            "entityRelationships/deleteEntityRelationship",
            {
              parent_entity_id: this.$route.params.entity_id,
              entity_relationship_id: id,
            }
          );

          this.loading = false;

          if (this.getEntityRelationshipDeleteStatus) {
            this.$message.success("Entity Relationship removed successfully");
            this.fetchEntityRelationships();
          }
        }
      } catch (err) {
        this.loading = false;
        console.error("deleteRelationship", err);
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async generateFields() {
      if (this.selectedEntity && this.selectedEntity.length) {
        this.allFields = [];
        let allFields = [];
        if (this.primaryFields && this.primaryFields.length) {
          let existedTemplates = this.selectedEntity.flatMap(
            (e) => e.template_id
          );
          let existedRepeatableTemplate = this.selectedEntity
            .filter((e) => e.is_repeatable)
            .flatMap((e) => e.template_id);
          this.primaryFields = this.primaryFields.filter(
            (e) =>
              existedTemplates.indexOf(e.template_id) != -1 &&
              existedRepeatableTemplate.indexOf(e.template_id) == -1
          );
        }
        await this.selectedEntity.map(async (template) => {
          if (template && template.templateInfo && !template.is_repeatable) {
            allFields = [
              ...allFields,
              ...this.applyCustomizationOnFields(
                this.getTemplateFields(template.templateInfo),
                template.customization,
                template
              ),
            ];
          }
          if (
            template.templateInfo &&
            !template.is_repeatable &&
            template.templateInfo.sections[0].fields
          ) {
            let fields = this.applyCustomizationOnFields(
              template.templateInfo.sections[0].fields,
              template.customization,
              template
            );
            fields.map((field) => {
              // let index = this._.findIndex(this.primaryFields, {
              //   key: field.key,
              //   template_id: template.templateInfo._id,
              // });
              let index = this.primaryFields.findIndex(
                (o) =>
                  o.key == field.key &&
                  o.template_id == template.templateInfo._id
              );
              if (index === -1) {
                this.allFields.push({
                  ...field,
                  template_id: template.templateInfo._id,
                  template_slug: template.templateInfo.slug,
                });
              }
              // }
            });
          }
        });
        this.primaryFields = this.primaryFields.map((e) => {
          const matchingField = (allFields || []).find(
            (el) => el.key === e.key
          );
          if (matchingField && matchingField.label !== e.label) {
            return { ...e, label: matchingField.label };
          }
          return e;
        });
      }
    },
    async fetchTemplates() {
      this.loading = true;
      let params = {
        get_all: true,
        populate_data: true,
        include_standard: true,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      // await this.$store.dispatch(
      //   "companyTemplates/getAllCompanyTemplates",
      //   params
      // );
      const getAllCompanyTemplatesData =
        await getAllCompanyTemplatesByPagination(params);
      this.allTemplates =
        getAllCompanyTemplatesData && getAllCompanyTemplatesData.data
          ? getAllCompanyTemplatesData.data
          : [];
      this.standardTemplate = this.allTemplates.find(
        (e) => e.slug == "contactdetails"
      );
      this.allTemplates = this.allTemplates.filter(
        (e) => e.slug !== "contactdetails"
      );
      this.loading = false;
    },

    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      // await this.$store.dispatch(
      //   "entityRelationships/fetchEntityRelationships",
      //   { entity_id: this.$route.params.entity_id }
      // );
      this.fetchedRelationships = await fetchEntityRelationships({
        entity_id: this.$route.params.entity_id,
      });
      this.relationshipsData = [...this.fetchedRelationships];
      // we have to add type owner of relationship

      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
        } else {
          e.owner_type = "CHILD";
        }
        return e;
      });
      //Fetch entity all template fields
      let allFields = [];
      let allTemplatesFields = await this.fetchEntityDetails(
        this.getEntityDataById?._id,
        false,
        false,
        false,
        true,
        true,
        true
      );
      allTemplatesFields.forEach((field) => {
        if (field.inputType == "ENTITY") {
          allFields.push(field._id);
        }
      });
      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (
          (e.owner_type == "CHILD" && !e.is_bidirectional) ||
          (e.representation == "FIELD" &&
            allFields.indexOf(e.field_id) == -1 &&
            e.parent_entity_id == this.$route.params.entity_id)
        ) {
          return false;
        } else {
          return true;
        }
      });

      this.loading = false;
    },
    checkAutomation() {
      this.saveClicked = !this.saveClicked;
    },
    async goBack() {
      if (this.selectedEntity.length && this.primaryFields.length) {
        let urlPath = "";
        let query = this.getNavigationQuery(this.$route.query);
        urlPath = `/entity`;
        this.$router.push({
          path: urlPath,
          query: query,
        });
        // if(this.entityName && this.getEntityDataById && this.getEntityDataById.name && this.getEntityDataById.name != this.entityName){
        //   this.updateEntity();
        // }
      } else {
        this.goBackToEntities = true;
        // this.$message.warning(
        //   "At least one template with primary field is required to project for the entity information"
        // );
      }
    },
    disableAddLimit() {
      if (
        this.newRelationship.limit_type == "field" &&
        this.newRelationship.limit_field == ""
      ) {
        return true;
      } else if (
        this.newRelationship.limit_type == "value" &&
        this.newRelationship.limit_value == null
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  beforeDestroy() {
    bus.$off("tabsConfigStatus");
    this.$store.commit("companyTemplates/setAllCompanyTemplatesData", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityCreatedStatus", null, { root: true });
    this.$store.commit("entities/setEntityDataById", null, { root: true });
    this.$store.commit("entities/setEntityDataUpdateStatus", null, {
      root: true,
    });

    this.$store.commit("documents/setAllTemplates", null, { root: true });
    this.$store.commit("entities/setAllEntities", null, { root: true });
    this.$store.commit("entityGroups/setEntityGroupErrors", {}, { root: true });
    this.$store.commit(
      "entityGroups/setSingleEntityGroupData",
      {},
      { root: true }
    );
    this.$store.commit(
      "entityGroups/setAllEntityGroupsData",
      { data: [], total: 0, page: 0 },
      { root: true }
    );
    this.$store.commit("entityRelationships/setEntityRelationships", [], {
      root: true,
    });
    this.$store.commit(
      "entityRelationships/setEntityRelationshipAddStatus",
      false,
      { root: true }
    );
    this.$store.commit(
      "entityRelationships/setEntityRelationshipDeleteStatus",
      false,
      { root: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  scrollbar-width: thin;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.Back-in-Entity {
  margin: 20px;
  height: 80px;
}
.actions {
  background: white !important;
}
.nameSty {
  font-size: 12px;
  margin-top: -16px;
  margin-left: 1px;
}
.inner-navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 100;
  background-color: #ffffff;
  border: 1px solid #efefef;
  margin-bottom: 0 !important;

  & > .d-flex {
    .back-btn {
      height: inherit;
    }
  }

  .left-block {
    .el-input__inner {
      min-width: 250px;
      max-width: 300px;
      background: #fff;
      height: 47px;
    }
  }
}

.input-type-one {
  font-size: 16px;

  &.entity-title-input {
    width: 300px;

    @media (max-width: 1000px) {
      width: 260px;
    }

    @media (max-width: 800px) {
      width: 130px;
    }

    @media (max-width: 500px) {
      width: 60px;
    }

    @media (max-width: 380px) {
      display: none;
    }
  }
}
.flow-top-bar {
  display: flex;
  padding: 0px 15px 5px 8px;
  justify-content: space-between;
  .left-side {
    display: flex;
    align-items: baseline;
    .form-title {
      &.hide-title {
        @media (max-width: 400px) {
          display: none;
        }
      }
    }
    .close-icon {
      margin-right: 10px;
      margin-left: 0px;
    }
    .document-flow {
      min-width: 450px;
      @media (max-width: 1000px) {
        min-width: 420px;
      }
      @media (max-width: 800px) {
        min-width: 300px;
      }
      @media (max-width: 600px) {
        min-width: 180px;
      }
      @media (max-width: 400px) {
        min-width: 150px;
      }
      @media (max-width: 350px) {
        min-width: 120px;
      }
    }
  }
}
.flow-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  z-index: 1000;
  background: white;
  color: #ffffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  text-align: center;
  //display: flex;
  justify-content: space-between;
  .flow-top-bar-left-area {
    display: flex;
    align-items: baseline;
    .go-back-link {
    }

    .icon-info-wrapper {
    }
  }
  .flow-top-bar-middle-area {
    &.document-flow-wrapper {
      min-width: 500px;
      @media (max-width: 1000px) {
        min-width: 500px;
      }
      @media (max-width: 900px) {
        min-width: 350px;
      }
      @media (max-width: 800px) {
        min-width: 300px;
      }
      @media (max-width: 600px) {
        min-width: 200px;
      }
      @media (max-width: 500px) {
        min-width: 180px;
      }
      @media (max-width: 400px) {
        min-width: 162px;
      }
      @media (max-width: 350px) {
        min-width: 162px;
      }
    }
  }
  .flow-top-bar-right-area {
  }
}
.is-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  z-index: 1000;
  background: white;
  color: #ffffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  text-align: center;
  //display: flex;
  justify-content: space-between;

  .flow-top-bar-left-area {
    display: flex;

    .go-back-link {
    }

    .icon-info-wrapper {
    }
  }

  .flow-top-bar-middle-area {
    &.document-flow-wrapper {
      min-width: 500px;

      @media (max-width: 1000px) {
        min-width: 500px;
      }

      @media (max-width: 900px) {
        min-width: 350px;
      }

      @media (max-width: 800px) {
        min-width: 300px;
      }

      @media (max-width: 600px) {
        min-width: 200px;
      }

      @media (max-width: 500px) {
        min-width: 180px;
      }

      @media (max-width: 400px) {
        min-width: 162px;
      }

      @media (max-width: 350px) {
        min-width: 162px;
      }
    }
  }

  .flow-top-bar-right-area {
  }
}

.close-icon {
  cursor: pointer;
}

.dragAndDrop {
  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 80px;
    max-width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    margin: auto;
  }

  .list-group-item {
    cursor: move;
    /* margin: 0.5rem;
    border-radius: 0.5rem;
    text-align: center;
    width: 100%; */
  }

  .list-group-item i {
    cursor: pointer;
  }

  .dropBox {
    margin: 1rem;
    border: 1px dashed #c5c5c5;
    border-radius: 0.5rem;

    @media (max-width: 500px) {
      margin: 0px !important;

      .list-group {
        margin: 0px !important;
        max-width: 100% !important;

        .list-group-item {
          padding: 8px 3px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.entity-information-popup {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .entity-type-radio {
      @media (max-width: 400px) {
        & > label {
          & > span {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#drag-template-background {
  .entity-editor-container {
    position: relative;

    // top: 72px !important;
    .document-container-wrapper {
      #field-group {
        background: white;
      }

      .form-builder {
        .entity-steps {
          width: calc(100% - 20px);
          margin-left: 10px;
        }
      }

      .el-scrollbar {
        .el-scrollbar__bar {
          &.is-horizontal {
            display: none;
          }
        }
      }
    }

    .right-side-bar {
      background: white;
    }
  }

  .dragAndDrop {
    // position: relative;
    // margin-top: 73px;
    // position: absolute;
    // top: 73px;
    // width: calc(100% - 270px);
    margin-left: 10%;
    height: 100vh;
    width: calc(100% - 10px);

    .outer-scroll {
      & > .el-scrollbar__bar {
        &.is-horizontal {
          display: none;
        }
      }
    }
  }
}

.is-open {
  #drag-template-background {
    .dragAndDrop {
      width: calc(100% - 270px);
    }
  }
}

.flow-top-bar {
  .flow-top-bar-middle-area {
    &.document-flow-wrapper {
      .document-flow {
        .el-step__main {
          .el-step__title {
            min-width: 76px !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// Existing styles...
@media only screen and (min-width: 1200px) {
  .el-col-lg-4 {
    display: contents;
  }
}
.truncate-text {
  white-space: nowrap; // Prevent text from wrapping
  overflow: hidden; // Hide the overflow
  text-overflow: ellipsis; // Add ellipsis (...) for clipped text
}
.content-spacing {
  margin: 30px 10px;
}
el-button__primary:hover {
  color: var(--primary-contrast-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.contain-length {
  max-height: 78vh;
  overflow-y: auto;
  scrollbar-width: thin;
}
</style>
